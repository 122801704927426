import { GlobalStyles } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { A11yStateProvider } from './hooks/useA11yState';
import { A11yThemeProvider } from './hooks/useA11yTheme';
import { AuthProvider } from './hooks/useAuth';
import { Suspense } from 'react';

const globalStyles = (
  <GlobalStyles
    styles={{
      'html, body, #root': { height: '100%' },
      'body > iframe' : { display: 'none !important' },
      '*': {
        overscrollBehaviorY: 'none',
      },
    }}
  />
);

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <A11yStateProvider>
        <A11yThemeProvider>
          <CssBaseline />

          {globalStyles}
          <Suspense fallback="loading...">
            <App />
          </Suspense>
        </A11yThemeProvider>
      </A11yStateProvider>
    </AuthProvider>
  </BrowserRouter>,
  document.querySelector('#root'),
);