import { Done, Search, Send } from "@mui/icons-material";
import { Box, Divider, IconButton, OutlinedInput, Paper, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function MainInput({onSubmit, placeholder}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const examples = [
    t('home.example1'),
    t('home.example2'),
    t('home.example3')
  ];

  return (
    <Paper
      component="form"
      onSubmit={onSubmit}
      noValidate
      sx={{
        width: '100%',
        p: 0.5
      }}
    >
      <OutlinedInput
        required
        fullWidth
        id="query"
        placeholder={placeholder}
        name="query"
        autoFocus
        startAdornment={
          <Search sx={{ mr: 1 }}/>
        }
      />
      <Box sx={{ p: 0.5 }}>
        <Typography variant="body2" sx={{ py:1, px: 3.5 }}>{t('home.examplesHeading')}</Typography>
        {examples.map((example, index) => {
          return (
            <div key={index}>
              <Stack sx={{my: 0.25}} direction="row" alignItems="center" gap={1}>
                <Done fontSize="small" color="success" />
                <Typography color="disabled" variant="caption">{example}</Typography>
                <IconButton onClick={() => navigate(`/demo/` + encodeURIComponent(example))} color="primary" size="small">
                  <Send fontSize="small" />
                </IconButton>
              </Stack>
              <Divider/>
            </div>
          );
        })}
      </Box>
    </Paper>
  );
}
