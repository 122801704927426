export default {
  "home": {
    "textHeading": "More than a seminar",
    "textSubHeading": "Our seminars impart know-how from operational practice as well as legal and collective bargaining knowledge. Another strength of our training work compared to commercial providers: You will later put what you have learned into practice in the workplace. Our 155 branches will support you in this.",
    "textInputPlaceholder": "Seminar search",
    "examplesHeading": "Example of queries",
    "example1": "I am a works council member and would like to improve my skills in employee representation, negotiation and legal compliance. What training courses could you recommend?",
    "example2": "I am an HR developer and am looking for training in employment law, employee rights, diversity and inclusion. What courses would you recommend?",
    "example3": "I am a manager and am interested in stress management, negotiation skills, health and safety practices to increase productivity and employee satisfaction. Can you help me?",
  },
  "demo": {
    "back": "Back"
  }
}