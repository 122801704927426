import { ArrowForward, Login, NavigateNext, Search } from '@mui/icons-material';
import { Button, Grid, InputAdornment, TextField, Typography, Link, Breadcrumbs } from '@mui/material';
import { Box } from '@mui/system';

export function Header() {
  const host = window.location.origin;
  const headerLinks1 = [
    {
      title: 'IG Metall',
      active: true
    },
    {
      title: 'Jugend',
      active: false
    },
    {
      title: 'Aktivenportal',
      active: false
    },
    {
      title: 'Presse',
      active: false
    },
  ];

  const headerLinks2 = [ 'IG Metall vor Ort', 'metall - Dein Magazin', 'Kampagnen', 'Seminare', 'Karriere', 'English' ];

  const headerLinks3 = [{
    title: 'Tarif',
    active: false
  }, {
    title: 'Im Betrieb',
    active: false
  }, {
    title: 'Politik & Gesellschaft',
    active: false
  }, {
    title: 'Mitmachen',
    active: false
  }, {
    title: 'Service',
    active: true
  }, {
    title: 'Über uns',
    active: false
  }];

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="#">IG Metall</Link>,
    <Link underline="hover" key="2" color="inherit" href="#">Service</Link>,
    <Typography key="3" color="#e3020e">Bildung & Seminare</Typography>,
  ];

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#f3f3f5',
          borderTop: '2px solid #e3020e'
        }}
      >
        <Grid container maxWidth='lg'>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', flexGrow: 1 }}>
              {headerLinks1.map((link) => (
                <Button
                  key={link.title}
                  variant="text"
                  sx={{
                    marginRight: 0,
                    fontSize: '11px',
                    lineHeight: '16px',
                    padding: 0,
                    whiteSpace: 'nowrap',
                    color: link.active ? '#e3020e' : 'rgba(24,22,24,0.5)',
                    p: 1,
                    '&:hover': {
                      color: '#e3020e',
                      backgroundColor: 'transparent'
                    }
                  }}
                >
                  {link.title}
                </Button>
              ))}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }}>
              {headerLinks2.map((page) => (
                <Button
                  key={page}
                  variant="text"
                  sx={{
                    marginRight: 0,
                    fontSize: '11px',
                    lineHeight: '16px',
                    padding: 0,
                    whiteSpace: 'nowrap',
                    color: 'rgba(24,22,24,0.5)',
                    p: 1,
                    '&:hover': {
                      color: '#e3020e',
                      backgroundColor: 'transparent'
                    }
                  }}
                >
                  {page}
                </Button>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#FFF',
        }}
      >
        <Grid container maxWidth='lg' sx={{ pt: 2, pb: 1 }}>
          <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <img style={{ height: '70px', width: '70px' }} src={host + '/assets/img/igmetall_logo.svg'} />
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }}>
              <TextField
                label=""
                placeholder='Suchbegriff eingeben'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <Button
                disableElevation={true}
                endIcon={<ArrowForward />}
              >
                Mitglied werden
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
            {headerLinks3.map((link) => (
              <Button
                key={link.title}
                variant="text"
                sx={{
                  mr: 2,
                  px: 0,
                  fontSize: '16px',
                  lineHeight: '32px',
                  padding: 0,
                  whiteSpace: 'nowrap',
                  borderBottom: link.active ? '2px solid #e3020e' : '2px solid transparent',
                  color: '#181618',
                  py: 1,
                  '&:hover': {
                    color: '#e3020e',
                    backgroundColor: 'transparent'
                  }
                }}
              >
                {link.title}
              </Button>
            ))}
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#f8f8f9',
          borderTop: '1px solid #ebebef'
        }}
      >
        <Grid container maxWidth='lg'>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', flexGrow: 1 }}>
              <Breadcrumbs
                separator={
                  <NavigateNext sx={{ fontSize: '0.6875rem' }} />
                }
                sx={{
                  '& li a, & li p': {
                    fontSize: '0.6875rem',
                    lineHeight: '2.25rem'
                  },
                  '& li a:hover': {
                    color: '#e3020e',
                    textDecoration: 'none'
                  }
                }}
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }}>
              <Button
                variant="text"
                disableElevation={true}
                startIcon={<Login />}
                sx={{
                  py: 0,
                  fontSize: '.6875rem',
                  lineHeight: '2.25rem',
                  color: '#646464',
                  '&:hover': {
                    color: '#e3020e',
                    backgroundColor: 'transparent'
                  }
                }}
              >
                Anmelden
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}