export default {
  "home": {
    "textHeading": "Mehr als ein Seminar",
    "textSubHeading": "Unsere Seminare vermitteln Know-how aus der betrieblichen Praxis sowie Rechts- und Tarif-Wissen. Eine weitere Stärke unserer Bildungsarbeit gegenüber kommerziellen Anbietern: Im Betrieb setzt Du später das um, was Du zuvor gelernt hast. Dabei unterstützen Dich unsere 155 Geschäftsstellen.",
    "textInputPlaceholder": "Seminarsuche",
    "examplesHeading": "Beispiel für Abfragen",
    "example1": "Ich bin Betriebsratmitglied und möchte meine Fähigkeiten in der Arbeitnehmervertretung, Verhandlungsführung und rechtlichen Compliance verbessern. Welche Schulungen könnten Sie dafür empfehlen?",
    "example2": "Ich bin Personalentwickler und suche nach Weiterbildung in Arbeitsrecht, Mitarbeiterrechten, Diversität und Inklusion. Welche Kurse empfehlen Sie?",
    "example3": "Ich bin Führungskraft und interessiere mich für Stressmanagement, Verhandlungsfähigkeiten, Gesundheits- und Sicherheitspraktiken, um Produktivität und Mitarbeiterzufriedenheit zu steigern. Kanst du mir helfen?",
  },
  "demo": {
    "back": "Zurück"
  }
}