import React from 'react';
import {
  Box,
  Container,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MainInput } from '../../components/MainInput';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../components/Header';

export default Home;
export function Home() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data:any = new FormData(event.currentTarget);
    const query = encodeURIComponent(data.get('query'));
    navigate(`/demo/` + query);
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        height: 'auto',
        backgroundImage: 'url(/assets/img/background.jpg)',
        backgroundSize: 'cover',
        pb: 5
      }}
    >
      <Header />
      <Container maxWidth="md">
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '400px',
            height: 'auto'
          }}
        >
          <Typography
            component="h1"
            variant="h1"
            sx={{
              color: '#fff',
              fontWeight: 700,
              fontSize: '4.375rem',
              lineHeight: '4.75rem',
              py: 1
            }}
          >
            {t('home.textHeading')}
          </Typography>
          <Typography
            component="h2"
            variant="h2"
            sx={{
              color: '#fff',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '28px',
              py: 3,
              textAlign: 'center'
            }}
          >
            {t('home.textSubHeading')}
          </Typography>
          <MainInput onSubmit={handleSubmit} placeholder={t('home.textInputPlaceholder')}/>
        </Box>
      </Container>
    </Box>
  );
}
