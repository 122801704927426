import { lazy } from 'react';
import { useRoutes } from 'react-router';
import './locales/i18n';
import Home from './pages/Home/Home';

const Demo = lazy(() => import('./pages/Demo/Demo'));

export function App() {
  return useRoutes([
    { path: '/', element: <Home /> },
    { path: '*', element: <Home /> },
    { path: '/demo/:query', element: <Demo /> }
  ]);
}
